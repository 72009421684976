"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var flowbite_react_1 = require("flowbite-react");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var DPE_1 = require("../../../../../@types/DPE");
var DpeReducer_1 = require("../../../../../store/DpeReducer");
var hook_1 = require("../../../../../store/hook");
var formatters_1 = require("../../../../../utils/formatters");
var JWTHeader_1 = require("../../../../../utils/JWTHeader");
var dpeQualifStep_1 = require("../dpeQualifStep");
function DpeStep1(_a) {
    var _b = _a.isDpeTreated, isDpeTreated = _b === void 0 ? false : _b, area = _a.area;
    var dispatch = (0, hook_1.useAppDispatch)();
    var selectedDpe = (0, hook_1.useAppSelector)(function (state) { return state.dpe.selectedDpe; });
    var jwt = (0, react_redux_1.useSelector)(function (state) { return state.auth.token; });
    var _c = __read((0, react_1.useState)(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read((0, react_1.useState)(''), 2), nbBienInputValue = _d[0], setNbBienInputValue = _d[1];
    var _e = __read((0, react_1.useState)(null), 2), nbBiens = _e[0], setNbBiens = _e[1];
    var handleValidButton = function () {
        dispatch((0, DpeReducer_1.setCurrentStep)(dpeQualifStep_1.DPE_QUALIF_STEPS.COMPLETING_OWNERS_DETAILS));
    };
    var handleNoOwnerButton = function () {
        setLoading('noOwner');
        fetch("/api/dpes/".concat(selectedDpe.id, "/treat"), {
            method: 'PATCH',
            headers: {
                Authorization: (0, JWTHeader_1.JWTAuthorization)(jwt),
                'Content-Type': 'application/json',
            },
        }).then(function (_response) { return _response.json(); })
            .then(function () {
            fetch("/api/dpes/".concat(selectedDpe.id, "/step/").concat(DPE_1.DpeStepsEnum.NO_OWNER), {
                method: 'PATCH',
                headers: {
                    Authorization: (0, JWTHeader_1.JWTAuthorization)(jwt),
                    'Content-Type': 'application/json',
                },
            }).then(function (_response) { return _response.json(); })
                .then(function () {
                setLoading(false);
                dispatch((0, DpeReducer_1.setCurrentStep)(dpeQualifStep_1.DPE_QUALIF_STEPS.REDIRECTION_TO_DPE_OR_IDENTIFIED));
            }).catch(function (error) {
                console.error('Error treating dpe:', error);
            });
        }).catch(function (error) {
            console.error('Error treating dpe:', error);
        });
    };
    var isButtonDisabled = !(nbBiens > 0);
    (0, react_1.useEffect)(function () {
        // Update store when nbBiens changes
        if (nbBiens !== null) {
            dispatch((0, DpeReducer_1.setNbOwner)(nbBiens));
        }
    }, [nbBiens, dispatch]);
    var handleInputChange = function (e) {
        var value = e.target.value;
        setNbBienInputValue(value);
        if (value === '') {
            setNbBiens(null);
        }
        else {
            // if input is NaN, set it to 0
            if (isNaN(value)) {
                setNbBiens(0);
                setNbBienInputValue('0');
                return;
            }
            var numValue = Math.min(Math.max(Number(value), 0), 50);
            // check if the value is a number
            setNbBiens(numValue);
        }
    };
    return (react_1.default.createElement("div", { className: "flex flex-grow flex-col" }, !isDpeTreated
        ? (react_1.default.createElement("div", null,
            react_1.default.createElement("div", { className: "mb-10 text-lg mb-5" },
                react_1.default.createElement("span", { className: "font-bold text-xl" }, "Ce que vous devez faire : "),
                ' ',
                "Identifiez si les caract\u00E9ristiques du DPE, rappel\u00E9es ci-dessus, vous permettent d'identifier des lots au sein de la copropri\u00E9t\u00E9 qui seraient \u00E0 l'origine du diagnostique de performance \u00E9nerg\u00E9tique."),
            react_1.default.createElement("div", { className: "flex-grow" }),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "mr-2 text-lg" },
                    (area != 0 && area != null && area != undefined) && (react_1.default.createElement(react_1.default.Fragment, null,
                        "D'apr\u00E8s vous, combien de lots avec une surface estimative de",
                        ' ',
                        react_1.default.createElement("b", null, (0, formatters_1.formatSurface)(area)),
                        ' ',
                        "peuvent correspondre dans la copropri\u00E9t\u00E9 indiqu\u00E9e ?")),
                    (area == 0 || area == null || area == undefined) && (react_1.default.createElement(react_1.default.Fragment, null, "D'apr\u00E8s vous, combien de lots avec ces crit\u00E8res peuvent correspondre dans la copropri\u00E9t\u00E9 indiqu\u00E9e ?"))),
                react_1.default.createElement("input", { type: "number", name: "nbBiens", id: "nbBiens", className: "rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-inset my-6", min: "0", max: "50", value: nbBienInputValue, onChange: handleInputChange })),
            react_1.default.createElement("div", { className: "flex-grow" }),
            react_1.default.createElement("div", { className: "md:flex justify-center md:grid md:grid-cols-3 md:gap-4" },
                react_1.default.createElement("button", { type: "button", className: "rounded-md bg-red-600 py-2 px-5 text-lg font-semibold text-white text-center shadow-sm w-full mt-5", onClick: handleNoOwnerButton }, loading === 'noOwner' ? (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(flowbite_react_1.Spinner, { "aria-label": "Center-aligned spinner", size: "lg", className: "mr-1" }),
                    "Chargement...")) : 'Je n\'ai pas trouvé de lots correspondant'),
                react_1.default.createElement("button", { type: "button", className: "rounded-md bg-rgpd-blue-dark py-2 px-5 text-lg font-semibold text-white text-center shadow-sm w-full mt-5", onClick: function () { dispatch((0, DpeReducer_1.resetAll)()); } }, "Annuler l'identification"),
                react_1.default.createElement("button", { type: "button", className: "rounded-md bg-rgpd-green py-2 px-5 text-lg font-semibold text-white text-center shadow-sm w-full mt-5 ".concat(isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''), onClick: handleValidButton, disabled: isButtonDisabled }, loading === 'validate' ? (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(flowbite_react_1.Spinner, { "aria-label": "Center-aligned spinner", size: "lg", className: "mr-1" }),
                    "Chargement...")) : 'Compléter les informations du ou des propriétaire(s) potentiel(s)'))))
        : (react_1.default.createElement("div", null,
            react_1.default.createElement("div", { className: "mr-2 text-lg" },
                area && (react_1.default.createElement(react_1.default.Fragment, null,
                    "D'apr\u00E8s vous, combien de lots avec une surface estimative de",
                    ' ',
                    react_1.default.createElement("b", null, (0, formatters_1.formatSurface)(area)),
                    ' ',
                    "peuvent correspondre dans la copropri\u00E9t\u00E9 indiqu\u00E9e ?")),
                !area && (react_1.default.createElement(react_1.default.Fragment, null, "D'apr\u00E8s vous, combien de lots avec ces crit\u00E8res peuvent correspondre dans la copropri\u00E9t\u00E9 indiqu\u00E9e ?"))),
            react_1.default.createElement("input", { type: "number", name: "nbBiens", id: "nbBiens", className: "rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-inset my-6", min: "0", max: "50", value: nbBienInputValue, onChange: handleInputChange }),
            react_1.default.createElement("div", { className: "flex justify-center mt-5" },
                react_1.default.createElement("button", { type: "button", className: "w-auto rounded-md bg-rgpd-green py-3 px-5 text-lg font-semibold text-white text-center shadow-sm ".concat(isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''), onClick: handleValidButton, disabled: isButtonDisabled }, loading === 'validate' ? (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(flowbite_react_1.Spinner, { "aria-label": "Center-aligned spinner", size: "lg", className: "mr-1" }),
                    "Chargement...")) : 'Compléter les informations du ou des propriétaire(s) potentiel(s)'))))));
}
exports.default = DpeStep1;
